import React from "react"
import Layout from "../components/layout"
import { Container } from 'react-grid-system';
import ContactUs from "../components/contact"

const SocialNetworks = () => (
  <div>
    <br/>
    <h2>Social Networks</h2>
    <div className="r1 b1">
    <table className="data-set data bkg-white">
    <thead>
      <tr>
        <th className="xl darken pl-1">Value</th>
        <th className="xl darken pl-1">Count</th>
        <th className="xl darken pl-1">Numeric</th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td><code>linkedin</code></td>
      <td>417,457,537</td>
      <td>417457537</td>
      </tr>
      <tr>
      <td><code>facebook</code></td>
      <td>27,307,624</td>
      <td>27307624</td>
      </tr>
      <tr>
      <td><code>github</code></td>
      <td>1,649,630</td>
      <td>1649630</td>
      </tr>
      <tr>
      <td><code>twitter</code></td>
      <td>917,834</td>
      <td>917834</td>
      </tr>
      <tr>
      <td><code>youtube</code></td>
      <td>429,857</td>
      <td>429857</td>
      </tr>
      <tr>
      <td><code>stackoverflow</code></td>
      <td>305,851</td>
      <td>305851</td>
      </tr>
      <tr>
      <td><code>angellist</code></td>
      <td>260,112</td>
      <td>260112</td>
      </tr>
      <tr>
      <td><code>quora</code></td>
      <td>204,676</td>
      <td>204676</td>
      </tr>
      <tr>
      <td><code>meetup</code></td>
      <td>184,684</td>
      <td>184684</td>
      </tr>
      <tr>
      <td><code>indeed</code></td>
      <td>121,059</td>
      <td>121059</td>
      </tr>
      <tr>
      <td><code>vimeo</code></td>
      <td>94,488</td>
      <td>94488</td>
      </tr>
      <tr>
      <td><code>flickr</code></td>
      <td>78,694</td>
      <td>78694</td>
      </tr>
      <tr>
      <td><code>wordpress</code></td>
      <td>56,730</td>
      <td>56730</td>
      </tr>
      <tr>
      <td><code>gravatar</code></td>
      <td>55,743</td>
      <td>55743</td>
      </tr>
      <tr>
      <td><code>instagram</code></td>
      <td>50,360</td>
      <td>50360</td>
      </tr>
      <tr>
      <td><code>dribbble</code></td>
      <td>36,928</td>
      <td>36928</td>
      </tr>
      <tr>
      <td><code>crunchbase</code></td>
      <td>31,811</td>
      <td>31811</td>
      </tr>
      <tr>
      <td><code>aboutme</code></td>
      <td>29,584</td>
      <td>29584</td>
      </tr>
      <tr>
      <td><code>behance</code></td>
      <td>20,692</td>
      <td>20692</td>
      </tr>
      <tr>
      <td><code>myspace</code></td>
      <td>12,690</td>
      <td>12690</td>
      </tr>
      <tr>
      <td><code>soundcloud</code></td>
      <td>8,608</td>
      <td>8608</td>
      </tr>
      <tr>
      <td><code>pinterest</code></td>
      <td>8,212</td>
      <td>8212</td>
      </tr>
      <tr>
      <td><code>google</code></td>
      <td>7,839</td>
      <td>7839</td>
      </tr>
      <tr>
      <td><code>xing</code></td>
      <td>4,044</td>
      <td>4044</td>
      </tr>
      <tr>
      <td><code>reddit</code></td>
      <td>373</td>
      <td>373</td>
      </tr>
      <tr>
      <td><code>foursquare</code></td>
      <td>147</td>
      <td>147</td>
      </tr>
      <tr>
      <td><code>gitlab</code></td>
      <td>113</td>
      <td>113</td>
      </tr>
      <tr>
      <td><code>klout</code></td>
      <td>32</td>
      <td>32</td>
      </tr>
    </tbody>
    </table>
    </div>
  </div>
)

const Coverage = () => (
  <div>
    <br/>
    <h2>Coverage</h2>
    <div className="r1 b1">
    <table className="data-set data bkg-white">
    <thead>
    <tr>
    <th className="xl darken pl-1">Field</th>
    <th className="xl darken pl-1">Count</th>
    <th className="xl darken pl-1">Numeric</th>
    <th className="xl darken pl-1">License Only</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td><code>total</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>birth_date</code></td>
    <td>36,933,423</td>
    <td>36933423</td>
    <td></td>
    </tr>
    <tr>
    <td><code>birth_date_fuzzy</code></td>
    <td>48,238,834</td>
    <td>48238834</td>
    <td></td>
    </tr>
    <tr>
    <td><code>certifications</code></td>
    <td>18,675,947</td>
    <td>18675947</td>
    <td></td>
    </tr>
    <tr>
    <td><code>certifications.end_date</code></td>
    <td>3,294,395</td>
    <td>3294395</td>
    <td></td>
    </tr>
    <tr>
    <td><code>certifications.name</code></td>
    <td>18,675,947</td>
    <td>18675947</td>
    <td></td>
    </tr>
    <tr>
    <td><code>certifications.organization</code></td>
    <td>13,981,117</td>
    <td>13981117</td>
    <td></td>
    </tr>
    <tr>
    <td><code>certifications.start_date</code></td>
    <td>10,693,924</td>
    <td>10693924</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education</code></td>
    <td>146,581,380</td>
    <td>146581380</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.degrees</code></td>
    <td>70,566,995</td>
    <td>70566995</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.end_date</code></td>
    <td>116,646,763</td>
    <td>116646763</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.gpa</code></td>
    <td>3,695,734</td>
    <td>3695734</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.majors</code></td>
    <td>75,800,779</td>
    <td>75800779</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.minors</code></td>
    <td>772,335</td>
    <td>772335</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.raw</code></td>
    <td>110,861,206</td>
    <td>110861206</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>education.school</code></td>
    <td>141,942,474</td>
    <td>141942474</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.school.location</code></td>
    <td>107,377,560</td>
    <td>107377560</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.school.name</code></td>
    <td>141,897,259</td>
    <td>141897259</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.school.profiles</code></td>
    <td>106,252,260</td>
    <td>106252260</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.school.type</code></td>
    <td>133,766,237</td>
    <td>133766237</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.school.website</code></td>
    <td>105,345,160</td>
    <td>105345160</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.start_date</code></td>
    <td>113,073,514</td>
    <td>113073514</td>
    <td></td>
    </tr>
    <tr>
    <td><code>education.summaries</code></td>
    <td>29,597,285</td>
    <td>29597285</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>emails</code></td>
    <td>582,723,634</td>
    <td>582723634</td>
    <td></td>
    </tr>
    <tr>
    <td><code>emails.address</code></td>
    <td>582,723,634</td>
    <td>582723634</td>
    <td></td>
    </tr>
    <tr>
    <td><code>emails.domain</code></td>
    <td>582,723,634</td>
    <td>582723634</td>
    <td></td>
    </tr>
    <tr>
    <td><code>emails.local</code></td>
    <td>582,723,634</td>
    <td>582723634</td>
    <td></td>
    </tr>
    <tr>
    <td><code>emails.type</code></td>
    <td>453,018,770</td>
    <td>453018770</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience</code></td>
    <td>410,135,538</td>
    <td>410135538</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company</code></td>
    <td>340,210,700</td>
    <td>340210700</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.country</code></td>
    <td>190,328,825</td>
    <td>190328825</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.founded</code></td>
    <td>167,341,134</td>
    <td>167341134</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.id</code></td>
    <td>219,153,828</td>
    <td>219153828</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>experience.company.industry</code></td>
    <td>218,911,535</td>
    <td>218911535</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.linkedin_size</code></td>
    <td>219,153,828</td>
    <td>219153828</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.location</code></td>
    <td>188,237,715</td>
    <td>188237715</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.name</code></td>
    <td>340,178,998</td>
    <td>340178998</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.profiles</code></td>
    <td>219,153,828</td>
    <td>219153828</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.raw</code></td>
    <td>340,206,442</td>
    <td>340206442</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>experience.company.region</code></td>
    <td>190,328,825</td>
    <td>190328825</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.size</code></td>
    <td>219,153,828</td>
    <td>219153828</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.company.website</code></td>
    <td>203,787,803</td>
    <td>203787803</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.end_date</code></td>
    <td>113,166,339</td>
    <td>113166339</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.is_primary</code></td>
    <td>410,135,538</td>
    <td>410135538</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.last_updated</code></td>
    <td>227,842,419</td>
    <td>227842419</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations</code></td>
    <td>154,780,021</td>
    <td>154780021</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.address_line_2</code></td>
    <td>17,592,947</td>
    <td>17592947</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.continent</code></td>
    <td>154,780,021</td>
    <td>154780021</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.country</code></td>
    <td>154,780,021</td>
    <td>154780021</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.geo</code></td>
    <td>138,795,174</td>
    <td>138795174</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.locality</code></td>
    <td>143,326,375</td>
    <td>143326375</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.name</code></td>
    <td>154,780,021</td>
    <td>154780021</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.postal_code</code></td>
    <td>83,976,057</td>
    <td>83976057</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.region</code></td>
    <td>146,236,836</td>
    <td>146236836</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.street_address</code></td>
    <td>81,276,041</td>
    <td>81276041</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.subregion</code></td>
    <td>117,908,112</td>
    <td>117908112</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.type</code></td>
    <td>154,780,021</td>
    <td>154780021</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.locations.zip_plus_4</code></td>
    <td>9,841,597</td>
    <td>9841597</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.most_recent</code></td>
    <td>410,135,538</td>
    <td>410135538</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.start_date</code></td>
    <td>162,455,180</td>
    <td>162455180</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.summaries</code></td>
    <td>84,708,289</td>
    <td>84708289</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>experience.title</code></td>
    <td>379,465,721</td>
    <td>379465721</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.title.levels</code></td>
    <td>129,910,739</td>
    <td>129910739</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.title.name</code></td>
    <td>379,465,721</td>
    <td>379465721</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.title.titles</code></td>
    <td>379,465,721</td>
    <td>379465721</td>
    <td></td>
    </tr>
    <tr>
    <td><code>experience.type</code></td>
    <td>410,135,538</td>
    <td>410135538</td>
    <td></td>
    </tr>
    <tr>
    <td><code>gender</code></td>
    <td>637,556,593</td>
    <td>637556593</td>
    <td></td>
    </tr>
    <tr>
    <td><code>industries</code></td>
    <td>277,458,503</td>
    <td>277458503</td>
    <td></td>
    </tr>
    <tr>
    <td><code>industries.is_primary</code></td>
    <td>277,458,503</td>
    <td>277458503</td>
    <td></td>
    </tr>
    <tr>
    <td><code>industries.name</code></td>
    <td>277,458,503</td>
    <td>277458503</td>
    <td></td>
    </tr>
    <tr>
    <td><code>inferred_salary</code></td>
    <td>129,047,164</td>
    <td>129047164</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>inferred_years_experience</code></td>
    <td>168,002,890</td>
    <td>168002890</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>interests</code></td>
    <td>54,938,255</td>
    <td>54938255</td>
    <td></td>
    </tr>
    <tr>
    <td><code>ips</code></td>
    <td>247,853,762</td>
    <td>247853762</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>languages</code></td>
    <td>88,398,436</td>
    <td>88398436</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>languages.name</code></td>
    <td>88,398,436</td>
    <td>88398436</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>languages.proficiency</code></td>
    <td>24,096,095</td>
    <td>24096095</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>linkedin_connections</code></td>
    <td>210,943,979</td>
    <td>210943979</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>locations</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.address_line_2</code></td>
    <td>24,855,108</td>
    <td>24855108</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.continent</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.country</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.geo</code></td>
    <td>430,179,907</td>
    <td>430179907</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.is_primary</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.last_updated</code></td>
    <td>339,769,597</td>
    <td>339769597</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.locality</code></td>
    <td>458,683,446</td>
    <td>458683446</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.most_recent</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.name</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.postal_code</code></td>
    <td>222,679,494</td>
    <td>222679494</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.region</code></td>
    <td>478,612,807</td>
    <td>478612807</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.street_address</code></td>
    <td>176,011,120</td>
    <td>176011120</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.subregion</code></td>
    <td>388,517,793</td>
    <td>388517793</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.type</code></td>
    <td>645,699,539</td>
    <td>645699539</td>
    <td></td>
    </tr>
    <tr>
    <td><code>locations.zip_plus_4</code></td>
    <td>72,010,454</td>
    <td>72010454</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.clean</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.first_name</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.is_primary</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.last_name</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.middle_initial</code></td>
    <td>157,316,523</td>
    <td>157316523</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.middle_name</code></td>
    <td>99,113,729</td>
    <td>99113729</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.name</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>names.suffix</code></td>
    <td>60,114</td>
    <td>60114</td>
    <td></td>
    </tr>
    <tr>
    <td><code>phone_numbers</code></td>
    <td>159,068,600</td>
    <td>159068600</td>
    <td></td>
    </tr>
    <tr>
    <td><code>phone_numbers.E164</code></td>
    <td>159,068,600</td>
    <td>159068600</td>
    <td></td>
    </tr>
    <tr>
    <td><code>phone_numbers.extension</code></td>
    <td>12,334</td>
    <td>12334</td>
    <td></td>
    </tr>
    <tr>
    <td><code>phone_numbers.type</code></td>
    <td>23,279</td>
    <td>23279</td>
    <td></td>
    </tr>
    <tr>
    <td><code>political_affiliation</code></td>
    <td>10,089,065</td>
    <td>10089065</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>primary</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.industry</code></td>
    <td>277,458,503</td>
    <td>277458503</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job</code></td>
    <td>241,127,406</td>
    <td>241127406</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company</code></td>
    <td>210,225,630</td>
    <td>210225630</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.country</code></td>
    <td>101,132,184</td>
    <td>101132184</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.founded</code></td>
    <td>85,800,634</td>
    <td>85800634</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.id</code></td>
    <td>125,130,424</td>
    <td>125130424</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>primary.job.company.industry</code></td>
    <td>124,997,474</td>
    <td>124997474</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.linkedin_size</code></td>
    <td>125,130,424</td>
    <td>125130424</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.location</code></td>
    <td>99,213,109</td>
    <td>99213109</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.name</code></td>
    <td>210,185,320</td>
    <td>210185320</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.profiles</code></td>
    <td>125,130,424</td>
    <td>125130424</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.raw</code></td>
    <td>210,220,108</td>
    <td>210220108</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>primary.job.company.region</code></td>
    <td>101,132,184</td>
    <td>101132184</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.size</code></td>
    <td>125,130,424</td>
    <td>125130424</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.company.website</code></td>
    <td>112,267,883</td>
    <td>112267883</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.end_date</code></td>
    <td>4,210,136</td>
    <td>4210136</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.last_updated</code></td>
    <td>227,842,419</td>
    <td>227842419</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.locations</code></td>
    <td>57,689,272</td>
    <td>57689272</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.locations.country</code></td>
    <td>57,689,272</td>
    <td>57689272</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.locations.locality</code></td>
    <td>50,346,205</td>
    <td>50346205</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.locations.name</code></td>
    <td>57,689,272</td>
    <td>57689272</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.locations.region</code></td>
    <td>51,522,951</td>
    <td>51522951</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.start_date</code></td>
    <td>124,607,648</td>
    <td>124607648</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.title</code></td>
    <td>226,350,726</td>
    <td>226350726</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.title.levels</code></td>
    <td>51,942,615</td>
    <td>51942615</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.title.name</code></td>
    <td>226,350,726</td>
    <td>226350726</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.job.title.titles</code></td>
    <td>226,350,726</td>
    <td>226350726</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.linkedin</code></td>
    <td>417,429,069</td>
    <td>417429069</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.location</code></td>
    <td>644,739,840</td>
    <td>644739840</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.location.country</code></td>
    <td>644,739,840</td>
    <td>644739840</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.location.last_updated</code></td>
    <td>339,754,114</td>
    <td>339754114</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.location.locality</code></td>
    <td>433,838,936</td>
    <td>433838936</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.location.name</code></td>
    <td>644,739,840</td>
    <td>644739840</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.location.region</code></td>
    <td>464,715,548</td>
    <td>464715548</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.name</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.name.clean</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.name.first_name</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.name.last_name</code></td>
    <td>857,288,052</td>
    <td>857288052</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.name.middle_name</code></td>
    <td>84,947,396</td>
    <td>84947396</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.personal_emails</code></td>
    <td>379,209,721</td>
    <td>379209721</td>
    <td></td>
    </tr>
    <tr>
    <td><code>primary.work_emails</code></td>
    <td>30,348,491</td>
    <td>30348491</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles</code></td>
    <td>431,052,794</td>
    <td>431052794</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles.aliases</code></td>
    <td>41,225,012</td>
    <td>41225012</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles.clean</code></td>
    <td>431,024,975</td>
    <td>431024975</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles.friends</code></td>
    <td>213,438,752</td>
    <td>213438752</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>profiles.ids</code></td>
    <td>262,015,439</td>
    <td>262015439</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles.is_primary</code></td>
    <td>417,429,069</td>
    <td>417429069</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles.network</code></td>
    <td>431,052,794</td>
    <td>431052794</td>
    <td></td>
    </tr>
    <tr>
    <td><code>profiles.username</code></td>
    <td>429,878,615</td>
    <td>429878615</td>
    <td></td>
    </tr>
    <tr>
    <td><code>skills</code></td>
    <td>134,642,867</td>
    <td>134642867</td>
    <td></td>
    </tr>
    <tr>
    <td><code>summaries</code></td>
    <td>211,392,643</td>
    <td>211392643</td>
    <td>T</td>
    </tr>
    <tr>
    <td><code>websites</code></td>
    <td>30,585,644</td>
    <td>30585644</td>
    <td>T</td>
    </tr>
    </tbody>
    </table>
    </div>
  </div>
)

const Countries = ()=>(
  <div>
    <br/>
    <h2>Countries</h2>
    <div className="r1 b1">
    <table className="data-set data">
    <thead>
      <tr>
      <th className="xl darken pl-1">Value</th>
      <th className="xl darken pl-1">Count</th>
      <th className="xl darken pl-1">Numeric</th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td><code>united states</code></td>
      <td>349,197,334</td>
      <td>349197334</td>
      </tr>
      <tr>
      <td><code>brazil</code></td>
      <td>38,025,861</td>
      <td>38025861</td>
      </tr>
      <tr>
      <td><code>india</code></td>
      <td>30,221,996</td>
      <td>30221996</td>
      </tr>
      <tr>
      <td><code>united kingdom</code></td>
      <td>21,824,123</td>
      <td>21824123</td>
      </tr>
      <tr>
      <td><code>canada</code></td>
      <td>13,291,431</td>
      <td>13291431</td>
      </tr>
      <tr>
      <td><code>france</code></td>
      <td>12,943,152</td>
      <td>12943152</td>
      </tr>
      <tr>
      <td><code>italy</code></td>
      <td>9,786,601</td>
      <td>9786601</td>
      </tr>
      <tr>
      <td><code>spain</code></td>
      <td>9,447,722</td>
      <td>9447722</td>
      </tr>
      <tr>
      <td><code>mexico</code></td>
      <td>8,719,652</td>
      <td>8719652</td>
      </tr>
      <tr>
      <td><code>australia</code></td>
      <td>7,888,020</td>
      <td>7888020</td>
      </tr>
      <tr>
      <td><code>indonesia</code></td>
      <td>6,650,126</td>
      <td>6650126</td>
      </tr>
      <tr>
      <td><code>netherlands</code></td>
      <td>6,640,511</td>
      <td>6640511</td>
      </tr>
      <tr>
      <td><code>germany</code></td>
      <td>6,026,408</td>
      <td>6026408</td>
      </tr>
      <tr>
      <td><code>turkey</code></td>
      <td>5,805,970</td>
      <td>5805970</td>
      </tr>
      <tr>
      <td><code>china</code></td>
      <td>5,660,103</td>
      <td>5660103</td>
      </tr>
      <tr>
      <td><code>argentina</code></td>
      <td>5,071,591</td>
      <td>5071591</td>
      </tr>
      <tr>
      <td><code>south africa</code></td>
      <td>5,015,783</td>
      <td>5015783</td>
      </tr>
      <tr>
      <td><code>colombia</code></td>
      <td>4,869,852</td>
      <td>4869852</td>
      </tr>
      <tr>
      <td><code>philippines</code></td>
      <td>4,177,152</td>
      <td>4177152</td>
      </tr>
      <tr>
      <td><code>chile</code></td>
      <td>3,495,081</td>
      <td>3495081</td>
      </tr>
      <tr>
      <td><code>peru</code></td>
      <td>3,340,281</td>
      <td>3340281</td>
      </tr>
      <tr>
      <td><code>belgium</code></td>
      <td>2,899,792</td>
      <td>2899792</td>
      </tr>
      <tr>
      <td><code>malaysia</code></td>
      <td>2,851,855</td>
      <td>2851855</td>
      </tr>
      <tr>
      <td><code>sweden</code></td>
      <td>2,806,188</td>
      <td>2806188</td>
      </tr>
      <tr>
      <td><code>nigeria</code></td>
      <td>2,782,468</td>
      <td>2782468</td>
      </tr>
      <tr>
      <td><code>pakistan</code></td>
      <td>2,651,700</td>
      <td>2651700</td>
      </tr>
      <tr>
      <td><code>united arab emirates</code></td>
      <td>2,618,796</td>
      <td>2618796</td>
      </tr>
      <tr>
      <td><code>poland</code></td>
      <td>2,366,836</td>
      <td>2366836</td>
      </tr>
      <tr>
      <td><code>venezuela</code></td>
      <td>2,230,419</td>
      <td>2230419</td>
      </tr>
      <tr>
      <td><code>portugal</code></td>
      <td>2,155,022</td>
      <td>2155022</td>
      </tr>
      <tr>
      <td><code>denmark</code></td>
      <td>2,141,527</td>
      <td>2141527</td>
      </tr>
      <tr>
      <td><code>switzerland</code></td>
      <td>2,104,683</td>
      <td>2104683</td>
      </tr>
      <tr>
      <td><code>iran</code></td>
      <td>1,985,145</td>
      <td>1985145</td>
      </tr>
      <tr>
      <td><code>romania</code></td>
      <td>1,872,815</td>
      <td>1872815</td>
      </tr>
      <tr>
      <td><code>russia</code></td>
      <td>1,820,438</td>
      <td>1820438</td>
      </tr>
      <tr>
      <td><code>saudi arabia</code></td>
      <td>1,813,421</td>
      <td>1813421</td>
      </tr>
      <tr>
      <td><code>egypt</code></td>
      <td>1,796,536</td>
      <td>1796536</td>
      </tr>
      <tr>
      <td><code>singapore</code></td>
      <td>1,699,572</td>
      <td>1699572</td>
      </tr>
      <tr>
      <td><code>norway</code></td>
      <td>1,631,756</td>
      <td>1631756</td>
      </tr>
      <tr>
      <td><code>ireland</code></td>
      <td>1,483,574</td>
      <td>1483574</td>
      </tr>
      <tr>
      <td><code>new zealand</code></td>
      <td>1,477,311</td>
      <td>1477311</td>
      </tr>
      <tr>
      <td><code>ecuador</code></td>
      <td>1,409,340</td>
      <td>1409340</td>
      </tr>
      <tr>
      <td><code>czechia</code></td>
      <td>1,378,057</td>
      <td>1378057</td>
      </tr>
      <tr>
      <td><code>kenya</code></td>
      <td>1,331,801</td>
      <td>1331801</td>
      </tr>
      <tr>
      <td><code>thailand</code></td>
      <td>1,298,499</td>
      <td>1298499</td>
      </tr>
      <tr>
      <td><code>morocco</code></td>
      <td>1,290,124</td>
      <td>1290124</td>
      </tr>
      <tr>
      <td><code>vietnam</code></td>
      <td>1,257,946</td>
      <td>1257946</td>
      </tr>
      <tr>
      <td><code>japan</code></td>
      <td>1,191,797</td>
      <td>1191797</td>
      </tr>
      <tr>
      <td><code>israel</code></td>
      <td>1,169,809</td>
      <td>1169809</td>
      </tr>
      <tr>
      <td><code>hong kong</code></td>
      <td>1,130,028</td>
      <td>1130028</td>
      </tr>
      <tr>
      <td><code>algeria</code></td>
      <td>1,086,709</td>
      <td>1086709</td>
      </tr>
      <tr>
      <td><code>bangladesh</code></td>
      <td>1,057,730</td>
      <td>1057730</td>
      </tr>
      <tr>
      <td><code>greece</code></td>
      <td>1,024,443</td>
      <td>1024443</td>
      </tr>
      <tr>
      <td><code>finland</code></td>
      <td>937,362</td>
      <td>937362</td>
      </tr>
      <tr>
      <td><code>austria</code></td>
      <td>875,737</td>
      <td>875737</td>
      </tr>
      <tr>
      <td><code>taiwan</code></td>
      <td>866,607</td>
      <td>866607</td>
      </tr>
      <tr>
      <td><code>ukraine</code></td>
      <td>826,813</td>
      <td>826813</td>
      </tr>
      <tr>
      <td><code>ghana</code></td>
      <td>816,388</td>
      <td>816388</td>
      </tr>
      <tr>
      <td><code>hungary</code></td>
      <td>761,140</td>
      <td>761140</td>
      </tr>
      <tr>
      <td><code>puerto rico</code></td>
      <td>709,242</td>
      <td>709242</td>
      </tr>
      <tr>
      <td><code>sri lanka</code></td>
      <td>704,725</td>
      <td>704725</td>
      </tr>
      <tr>
      <td><code>tunisia</code></td>
      <td>654,440</td>
      <td>654440</td>
      </tr>
      <tr>
      <td><code>costa rica</code></td>
      <td>646,058</td>
      <td>646058</td>
      </tr>
      <tr>
      <td><code>dominican republic</code></td>
      <td>591,486</td>
      <td>591486</td>
      </tr>
      <tr>
      <td><code>serbia</code></td>
      <td>555,215</td>
      <td>555215</td>
      </tr>
      <tr>
      <td><code>uruguay</code></td>
      <td>516,173</td>
      <td>516173</td>
      </tr>
      <tr>
      <td><code>guatemala</code></td>
      <td>506,223</td>
      <td>506223</td>
      </tr>
      <tr>
      <td><code>croatia</code></td>
      <td>498,143</td>
      <td>498143</td>
      </tr>
      <tr>
      <td><code>bulgaria</code></td>
      <td>493,167</td>
      <td>493167</td>
      </tr>
      <tr>
      <td><code>zimbabwe</code></td>
      <td>492,100</td>
      <td>492100</td>
      </tr>
      <tr>
      <td><code>qatar</code></td>
      <td>480,205</td>
      <td>480205</td>
      </tr>
      <tr>
      <td><code>jordan</code></td>
      <td>461,754</td>
      <td>461754</td>
      </tr>
      <tr>
      <td><code>lebanon</code></td>
      <td>452,953</td>
      <td>452953</td>
      </tr>
      <tr>
      <td><code>uganda</code></td>
      <td>452,070</td>
      <td>452070</td>
      </tr>
      <tr>
      <td><code>slovakia</code></td>
      <td>446,122</td>
      <td>446122</td>
      </tr>
      <tr>
      <td><code>tanzania</code></td>
      <td>429,374</td>
      <td>429374</td>
      </tr>
      <tr>
      <td><code>bolivia</code></td>
      <td>426,915</td>
      <td>426915</td>
      </tr>
      <tr>
      <td><code>panama</code></td>
      <td>410,388</td>
      <td>410388</td>
      </tr>
      <tr>
      <td><code>côte d’ivoire</code></td>
      <td>388,283</td>
      <td>388283</td>
      </tr>
      <tr>
      <td><code>cameroon</code></td>
      <td>377,570</td>
      <td>377570</td>
      </tr>
      <tr>
      <td><code>kuwait</code></td>
      <td>357,500</td>
      <td>357500</td>
      </tr>
      <tr>
      <td><code>nepal</code></td>
      <td>354,739</td>
      <td>354739</td>
      </tr>
      <tr>
      <td><code>iraq</code></td>
      <td>307,221</td>
      <td>307221</td>
      </tr>
      <tr>
      <td><code>jamaica</code></td>
      <td>304,694</td>
      <td>304694</td>
      </tr>
      <tr>
      <td><code>senegal</code></td>
      <td>303,350</td>
      <td>303350</td>
      </tr>
      <tr>
      <td><code>lithuania</code></td>
      <td>291,982</td>
      <td>291982</td>
      </tr>
      <tr>
      <td><code>ethiopia</code></td>
      <td>288,542</td>
      <td>288542</td>
      </tr>
      <tr>
      <td><code>el salvador</code></td>
      <td>280,140</td>
      <td>280140</td>
      </tr>
      <tr>
      <td><code>angola</code></td>
      <td>275,681</td>
      <td>275681</td>
      </tr>
      <tr>
      <td><code>slovenia</code></td>
      <td>271,767</td>
      <td>271767</td>
      </tr>
      <tr>
      <td><code>oman</code></td>
      <td>265,140</td>
      <td>265140</td>
      </tr>
      <tr>
      <td><code>trinidad and tobago</code></td>
      <td>259,447</td>
      <td>259447</td>
      </tr>
      <tr>
      <td><code>democratic republic of the congo</code></td>
      <td>249,694</td>
      <td>249694</td>
      </tr>
      <tr>
      <td><code>honduras</code></td>
      <td>242,947</td>
      <td>242947</td>
      </tr>
      <tr>
      <td><code>zambia</code></td>
      <td>240,081</td>
      <td>240081</td>
      </tr>
      <tr>
      <td><code>paraguay</code></td>
      <td>239,447</td>
      <td>239447</td>
      </tr>
      <tr>
      <td><code>kazakhstan</code></td>
      <td>238,785</td>
      <td>238785</td>
      </tr>
      <tr>
      <td><code>latvia</code></td>
      <td>233,198</td>
      <td>233198</td>
      </tr>
      <tr>
      <td><code>nicaragua</code></td>
      <td>223,106</td>
      <td>223106</td>
      </tr>
      <tr>
      <td><code>albania</code></td>
      <td>222,932</td>
      <td>222932</td>
      </tr>
      <tr>
      <td><code>cambodia</code></td>
      <td>212,780</td>
      <td>212780</td>
      </tr>
      <tr>
      <td><code>azerbaijan</code></td>
      <td>198,456</td>
      <td>198456</td>
      </tr>
      <tr>
      <td><code>mozambique</code></td>
      <td>196,199</td>
      <td>196199</td>
      </tr>
      <tr>
      <td><code>cyprus</code></td>
      <td>195,461</td>
      <td>195461</td>
      </tr>
      <tr>
      <td><code>bahrain</code></td>
      <td>193,209</td>
      <td>193209</td>
      </tr>
      <tr>
      <td><code>iceland</code></td>
      <td>188,045</td>
      <td>188045</td>
      </tr>
      <tr>
      <td><code>myanmar</code></td>
      <td>186,048</td>
      <td>186048</td>
      </tr>
      <tr>
      <td><code>luxembourg</code></td>
      <td>181,525</td>
      <td>181525</td>
      </tr>
      <tr>
      <td><code>afghanistan</code></td>
      <td>177,082</td>
      <td>177082</td>
      </tr>
      <tr>
      <td><code>mauritius</code></td>
      <td>172,720</td>
      <td>172720</td>
      </tr>
      <tr>
      <td><code>botswana</code></td>
      <td>169,466</td>
      <td>169466</td>
      </tr>
      <tr>
      <td><code>sudan</code></td>
      <td>165,140</td>
      <td>165140</td>
      </tr>
      <tr>
      <td><code>macedonia</code></td>
      <td>165,023</td>
      <td>165023</td>
      </tr>
      <tr>
      <td><code>bosnia and herzegovina</code></td>
      <td>161,053</td>
      <td>161053</td>
      </tr>
      <tr>
      <td><code>syria</code></td>
      <td>157,853</td>
      <td>157853</td>
      </tr>
      <tr>
      <td><code>estonia</code></td>
      <td>150,026</td>
      <td>150026</td>
      </tr>
      <tr>
      <td><code>belarus</code></td>
      <td>140,160</td>
      <td>140160</td>
      </tr>
      <tr>
      <td><code>namibia</code></td>
      <td>139,406</td>
      <td>139406</td>
      </tr>
      <tr>
      <td><code>malta</code></td>
      <td>135,810</td>
      <td>135810</td>
      </tr>
      <tr>
      <td><code>benin</code></td>
      <td>127,769</td>
      <td>127769</td>
      </tr>
      <tr>
      <td><code>haiti</code></td>
      <td>127,277</td>
      <td>127277</td>
      </tr>
      <tr>
      <td><code>rwanda</code></td>
      <td>120,854</td>
      <td>120854</td>
      </tr>
      <tr>
      <td><code>moldova</code></td>
      <td>120,748</td>
      <td>120748</td>
      </tr>
      <tr>
      <td><code>burkina faso</code></td>
      <td>118,897</td>
      <td>118897</td>
      </tr>
      <tr>
      <td><code>madagascar</code></td>
      <td>114,367</td>
      <td>114367</td>
      </tr>
      <tr>
      <td><code>papua new guinea</code></td>
      <td>108,186</td>
      <td>108186</td>
      </tr>
      <tr>
      <td><code>cuba</code></td>
      <td>107,607</td>
      <td>107607</td>
      </tr>
      <tr>
      <td><code>palestine</code></td>
      <td>107,085</td>
      <td>107085</td>
      </tr>
      <tr>
      <td><code>libya</code></td>
      <td>106,146</td>
      <td>106146</td>
      </tr>
      <tr>
      <td><code>armenia</code></td>
      <td>105,434</td>
      <td>105434</td>
      </tr>
      <tr>
      <td><code>malawi</code></td>
      <td>105,346</td>
      <td>105346</td>
      </tr>
      <tr>
      <td><code>mali</code></td>
      <td>97,090</td>
      <td>97090</td>
      </tr>
      <tr>
      <td><code>togo</code></td>
      <td>91,378</td>
      <td>91378</td>
      </tr>
      <tr>
      <td><code>fiji</code></td>
      <td>91,205</td>
      <td>91205</td>
      </tr>
      <tr>
      <td><code>bahamas</code></td>
      <td>91,057</td>
      <td>91057</td>
      </tr>
      <tr>
      <td><code>montenegro</code></td>
      <td>89,791</td>
      <td>89791</td>
      </tr>
      <tr>
      <td><code>yemen</code></td>
      <td>88,216</td>
      <td>88216</td>
      </tr>
      <tr>
      <td><code>mongolia</code></td>
      <td>87,250</td>
      <td>87250</td>
      </tr>
      <tr>
      <td><code>gabon</code></td>
      <td>77,794</td>
      <td>77794</td>
      </tr>
      <tr>
      <td><code>south korea</code></td>
      <td>70,960</td>
      <td>70960</td>
      </tr>
      <tr>
      <td><code>barbados</code></td>
      <td>69,044</td>
      <td>69044</td>
      </tr>
      <tr>
      <td><code>uzbekistan</code></td>
      <td>68,597</td>
      <td>68597</td>
      </tr>
      <tr>
      <td><code>guam</code></td>
      <td>65,704</td>
      <td>65704</td>
      </tr>
      <tr>
      <td><code>bermuda</code></td>
      <td>58,777</td>
      <td>58777</td>
      </tr>
      <tr>
      <td><code>laos</code></td>
      <td>58,041</td>
      <td>58041</td>
      </tr>
      <tr>
      <td><code>guadeloupe</code></td>
      <td>57,753</td>
      <td>57753</td>
      </tr>
      <tr>
      <td><code>réunion</code></td>
      <td>54,502</td>
      <td>54502</td>
      </tr>
      <tr>
      <td><code>maldives</code></td>
      <td>54,260</td>
      <td>54260</td>
      </tr>
      <tr>
      <td><code>guinea</code></td>
      <td>54,188</td>
      <td>54188</td>
      </tr>
      <tr>
      <td><code>liberia</code></td>
      <td>53,478</td>
      <td>53478</td>
      </tr>
      <tr>
      <td><code>macau</code></td>
      <td>53,123</td>
      <td>53123</td>
      </tr>
      <tr>
      <td><code>netherlands antilles</code></td>
      <td>50,712</td>
      <td>50712</td>
      </tr>
      <tr>
      <td><code>martinique</code></td>
      <td>49,535</td>
      <td>49535</td>
      </tr>
      <tr>
      <td><code>brunei</code></td>
      <td>48,095</td>
      <td>48095</td>
      </tr>
      <tr>
      <td><code>suriname</code></td>
      <td>47,907</td>
      <td>47907</td>
      </tr>
      <tr>
      <td><code>niger</code></td>
      <td>44,199</td>
      <td>44199</td>
      </tr>
      <tr>
      <td><code>guyana</code></td>
      <td>40,901</td>
      <td>40901</td>
      </tr>
      <tr>
      <td><code>sierra leone</code></td>
      <td>40,491</td>
      <td>40491</td>
      </tr>
      <tr>
      <td><code>new caledonia</code></td>
      <td>39,505</td>
      <td>39505</td>
      </tr>
      <tr>
      <td><code>bhutan</code></td>
      <td>38,477</td>
      <td>38477</td>
      </tr>
      <tr>
      <td><code>somalia</code></td>
      <td>38,262</td>
      <td>38262</td>
      </tr>
      <tr>
      <td><code>kyrgyzstan</code></td>
      <td>37,745</td>
      <td>37745</td>
      </tr>
      <tr>
      <td><code>cape verde</code></td>
      <td>36,950</td>
      <td>36950</td>
      </tr>
      <tr>
      <td><code>lesotho</code></td>
      <td>36,401</td>
      <td>36401</td>
      </tr>
      <tr>
      <td><code>swaziland</code></td>
      <td>36,096</td>
      <td>36096</td>
      </tr>
      <tr>
      <td><code>belize</code></td>
      <td>35,361</td>
      <td>35361</td>
      </tr>
      <tr>
      <td><code>gambia</code></td>
      <td>35,145</td>
      <td>35145</td>
      </tr>
      <tr>
      <td><code>mauritania</code></td>
      <td>33,482</td>
      <td>33482</td>
      </tr>
      <tr>
      <td><code>french polynesia</code></td>
      <td>33,028</td>
      <td>33028</td>
      </tr>
      <tr>
      <td><code>burundi</code></td>
      <td>32,924</td>
      <td>32924</td>
      </tr>
      <tr>
      <td><code>aruba</code></td>
      <td>30,844</td>
      <td>30844</td>
      </tr>
      <tr>
      <td><code>kosovo</code></td>
      <td>29,444</td>
      <td>29444</td>
      </tr>
      <tr>
      <td><code>cayman islands</code></td>
      <td>28,893</td>
      <td>28893</td>
      </tr>
      <tr>
      <td><code>saint lucia</code></td>
      <td>28,212</td>
      <td>28212</td>
      </tr>
      <tr>
      <td><code>monaco</code></td>
      <td>27,921</td>
      <td>27921</td>
      </tr>
      <tr>
      <td><code>isle of man</code></td>
      <td>27,832</td>
      <td>27832</td>
      </tr>
      <tr>
      <td><code>jersey</code></td>
      <td>27,434</td>
      <td>27434</td>
      </tr>
      <tr>
      <td><code>andorra</code></td>
      <td>23,989</td>
      <td>23989</td>
      </tr>
      <tr>
      <td><code>chad</code></td>
      <td>23,117</td>
      <td>23117</td>
      </tr>
      <tr>
      <td><code>antigua and barbuda</code></td>
      <td>21,287</td>
      <td>21287</td>
      </tr>
      <tr>
      <td><code>djibouti</code></td>
      <td>20,840</td>
      <td>20840</td>
      </tr>
      <tr>
      <td><code>tajikistan</code></td>
      <td>19,701</td>
      <td>19701</td>
      </tr>
      <tr>
      <td><code>french guiana</code></td>
      <td>19,082</td>
      <td>19082</td>
      </tr>
      <tr>
      <td><code>equatorial guinea</code></td>
      <td>18,280</td>
      <td>18280</td>
      </tr>
      <tr>
      <td><code>turkmenistan</code></td>
      <td>18,002</td>
      <td>18002</td>
      </tr>
      <tr>
      <td><code>grenada</code></td>
      <td>17,058</td>
      <td>17058</td>
      </tr>
      <tr>
      <td><code>seychelles</code></td>
      <td>16,535</td>
      <td>16535</td>
      </tr>
      <tr>
      <td><code>american samoa</code></td>
      <td>16,175</td>
      <td>16175</td>
      </tr>
      <tr>
      <td><code>gibraltar</code></td>
      <td>15,692</td>
      <td>15692</td>
      </tr>
      <tr>
      <td><code>central african republic</code></td>
      <td>15,398</td>
      <td>15398</td>
      </tr>
      <tr>
      <td><code>south sudan</code></td>
      <td>15,037</td>
      <td>15037</td>
      </tr>
      <tr>
      <td><code>guernsey</code></td>
      <td>15,019</td>
      <td>15019</td>
      </tr>
      <tr>
      <td><code>saint vincent and the grenadines</code></td>
      <td>14,632</td>
      <td>14632</td>
      </tr>
      <tr>
      <td><code>dominica</code></td>
      <td>12,290</td>
      <td>12290</td>
      </tr>
      <tr>
      <td><code>faroe islands</code></td>
      <td>12,192</td>
      <td>12192</td>
      </tr>
      <tr>
      <td><code>turks and caicos islands</code></td>
      <td>11,325</td>
      <td>11325</td>
      </tr>
      <tr>
      <td><code>saint kitts and nevis</code></td>
      <td>11,127</td>
      <td>11127</td>
      </tr>
      <tr>
      <td><code>greenland</code></td>
      <td>11,094</td>
      <td>11094</td>
      </tr>
      <tr>
      <td><code>tuvalu</code></td>
      <td>10,823</td>
      <td>10823</td>
      </tr>
      <tr>
      <td><code>northern mariana islands</code></td>
      <td>10,628</td>
      <td>10628</td>
      </tr>
      <tr>
      <td><code>timor-leste</code></td>
      <td>10,338</td>
      <td>10338</td>
      </tr>
      <tr>
      <td><code>liechtenstein</code></td>
      <td>10,285</td>
      <td>10285</td>
      </tr>
      <tr>
      <td><code>vanuatu</code></td>
      <td>10,088</td>
      <td>10088</td>
      </tr>
      <tr>
      <td><code>solomon islands</code></td>
      <td>9,965</td>
      <td>9965</td>
      </tr>
      <tr>
      <td><code>comoros</code></td>
      <td>8,005</td>
      <td>8005</td>
      </tr>
      <tr>
      <td><code>mayotte</code></td>
      <td>7,998</td>
      <td>7998</td>
      </tr>
      <tr>
      <td><code>samoa</code></td>
      <td>7,984</td>
      <td>7984</td>
      </tr>
      <tr>
      <td><code>tonga</code></td>
      <td>7,670</td>
      <td>7670</td>
      </tr>
      <tr>
      <td><code>san marino</code></td>
      <td>7,466</td>
      <td>7466</td>
      </tr>
      <tr>
      <td><code>anguilla</code></td>
      <td>7,012</td>
      <td>7012</td>
      </tr>
      <tr>
      <td><code>Åland islands</code></td>
      <td>6,948</td>
      <td>6948</td>
      </tr>
      <tr>
      <td><code>micronesia</code></td>
      <td>6,911</td>
      <td>6911</td>
      </tr>
      <tr>
      <td><code>u.s. virgin islands</code></td>
      <td>6,738</td>
      <td>6738</td>
      </tr>
      <tr>
      <td><code>guinea-bissau</code></td>
      <td>6,551</td>
      <td>6551</td>
      </tr>
      <tr>
      <td><code>kiribati</code></td>
      <td>6,534</td>
      <td>6534</td>
      </tr>
      <tr>
      <td><code>georgia</code></td>
      <td>6,454</td>
      <td>6454</td>
      </tr>
      <tr>
      <td><code>eritrea</code></td>
      <td>5,991</td>
      <td>5991</td>
      </tr>
      <tr>
      <td><code>marshall islands</code></td>
      <td>5,718</td>
      <td>5718</td>
      </tr>
      <tr>
      <td><code>antarctica</code></td>
      <td>5,424</td>
      <td>5424</td>
      </tr>
      <tr>
      <td><code>são tomé and príncipe</code></td>
      <td>5,254</td>
      <td>5254</td>
      </tr>
      <tr>
      <td><code>british virgin islands</code></td>
      <td>5,189</td>
      <td>5189</td>
      </tr>
      <tr>
      <td><code>cook islands</code></td>
      <td>3,836</td>
      <td>3836</td>
      </tr>
      <tr>
      <td><code>palau</code></td>
      <td>3,392</td>
      <td>3392</td>
      </tr>
      <tr>
      <td><code>vatican city</code></td>
      <td>2,730</td>
      <td>2730</td>
      </tr>
      <tr>
      <td><code>montserrat</code></td>
      <td>1,693</td>
      <td>1693</td>
      </tr>
      <tr>
      <td><code>british indian ocean territory</code></td>
      <td>1,642</td>
      <td>1642</td>
      </tr>
      <tr>
      <td><code>nauru</code></td>
      <td>1,464</td>
      <td>1464</td>
      </tr>
      <tr>
      <td><code>western sahara</code></td>
      <td>1,442</td>
      <td>1442</td>
      </tr>
      <tr>
      <td><code>christmas island</code></td>
      <td>1,431</td>
      <td>1431</td>
      </tr>
      <tr>
      <td><code>saint helena</code></td>
      <td>1,389</td>
      <td>1389</td>
      </tr>
      <tr>
      <td><code>french southern territories</code></td>
      <td>1,279</td>
      <td>1279</td>
      </tr>
      <tr>
      <td><code>niue</code></td>
      <td>1,264</td>
      <td>1264</td>
      </tr>
      <tr>
      <td><code>saint pierre and miquelon</code></td>
      <td>1,241</td>
      <td>1241</td>
      </tr>
      <tr>
      <td><code>norfolk island</code></td>
      <td>1,054</td>
      <td>1054</td>
      </tr>
      <tr>
      <td><code>wallis and futuna</code></td>
      <td>972</td>
      <td>972</td>
      </tr>
      <tr>
      <td><code>north korea</code></td>
      <td>903</td>
      <td>903</td>
      </tr>
      <tr>
      <td><code>falkland islands</code></td>
      <td>870</td>
      <td>870</td>
      </tr>
      <tr>
      <td><code>pitcairn</code></td>
      <td>760</td>
      <td>760</td>
      </tr>
      <tr>
      <td><code>tokelau</code></td>
      <td>745</td>
      <td>745</td>
      </tr>
      <tr>
      <td><code>svalbard and jan mayen</code></td>
      <td>577</td>
      <td>577</td>
      </tr>
      <tr>
      <td><code>cocos (keeling) islands</code></td>
      <td>536</td>
      <td>536</td>
      </tr>
      <tr>
      <td><code>curaçao</code></td>
      <td>502</td>
      <td>502</td>
      </tr>
      <tr>
      <td><code>republic of the congo</code></td>
      <td>346</td>
      <td>346</td>
      </tr>
      <tr>
      <td><code>bouvet island</code></td>
      <td>248</td>
      <td>248</td>
      </tr>
      <tr>
      <td><code>heard island and mcdonald islands</code></td>
      <td>191</td>
      <td>191</td>
      </tr>
      <tr>
      <td><code>united states minor outlying islands</code></td>
      <td>171</td>
      <td>171</td>
      </tr>
      <tr>
      <td><code>saint barthélemy</code></td>
      <td>77</td>
      <td>77</td>
      </tr>
      <tr>
      <td><code>caribbean netherlands</code></td>
      <td>36</td>
      <td>36</td>
      </tr>
      <tr>
      <td><code>saint martin</code></td>
      <td>36</td>
      <td>36</td>
      </tr>
      <tr>
      <td><code>south georgia and the south sandwich islands</code></td>
      <td>31</td>
      <td>31</td>
      </tr>
      <tr>
      <td><code>sint maarten</code></td>
      <td>21</td>
      <td>21</td>
    </tr>
    </tbody>
    </table>
    </div>
  </div>
)


export default ({data}) => {
  return (
    <Layout>
      <Container >
        <SocialNetworks />
        <Coverage/>
        <Countries/>
        <br /><br /><br />
      </Container>
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark (sort: { fields: [frontmatter___date], order: DESC }, limit:3){
      nodes {
        html
        excerpt( pruneLength: 120 )
        frontmatter {
          month:date(formatString: "MMMM")
          year:date(formatString: "YYYY")
          day:date(formatString: "DD")
          date
          title
        }
        fields {
          slug
        }
      }
    }
  }
`
